.root {
  background-color: white;
}

.label {
  padding: 16px 24px 0px;
}

@media screen and (max-width: 768px) {
  .label {
    padding: 16px 16px 0px;
  }
}
