.root {
  color: #272522;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
}

@media screen and (max-width: 768px) {
  .root {
    padding: 12px 16px;
  }
}
