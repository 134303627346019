.root {
  padding: 24px !important;
  display: flex;
  gap: 24px;
  flex: 1;
  height: auto !important;
}

.root::-webkit-scrollbar {
  display: none;
}

.paper {
  min-width: 78vw;
}

.dialogRoot {
  display: flex;
}

.interface {
  flex: 3;
}

.hr {
  border-top: 2px solid #e7e6e4;
}

.previewContainer {
  border-left: 1px solid #e4e4e4;
  padding-left: 24px;
  flex: 1;
  position: sticky;
  top: 0px;
  overflow-y: auto;
}

.previewContainer::-webkit-scrollbar {
  display: none;
}

@keyframes templatesAnim {
  from {
    transform: translateY(10%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.input {
  width: 50%;
  background-color: white;
  height: 48px;
}

@media screen and (max-width: 768px) {
  .root {
    padding: 16px !important;
    background: #f5f3fb;
  }

  .hr {
    margin: 0px -16px;
  }

  .input {
    width: auto;
  }
}
