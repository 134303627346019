.templates {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  animation: templatesAnim 0.3s ease forwards;
  padding-bottom: 24px;
}

@media screen and (max-width: 768px) {
  .templates {
    padding-bottom: 16px;
    grid-template-columns: repeat(1, 1fr);
  }
}
