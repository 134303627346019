.root {
  border-radius: 10px;
  border: 0.25px solid #d8dde9;
  box-shadow: 0px 4px 6px 0px rgba(216, 221, 233, 0.25);
}

.header {
  padding: 16px 12px;
  color: #21272a;
  line-height: normal;
  border-bottom: 1px solid #e7e6e4;
}

.body {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.commLabel {
  font-size: 10px;
  line-height: 11.93px;
  opacity: 0.5;
  margin-top: 8px;
  margin-bottom: 0px;
}
