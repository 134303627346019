.root {
  border-radius: 10px;
  border: 0.25px solid #d8dde9;
  box-shadow: 0px 4px 6px 0px rgba(216, 221, 233, 0.25);
}

.header {
  border-bottom: 1px solid #e7e6e4;
  padding: 16px 12px;
  gap: 4px;
}

.label {
  color: #21272a;
  font-size: 14px;
  line-height: normal;
  display: inline-block;
  flex: 1 1 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.actionsContainer {
  gap: 12px;
}

.actionsContainer svg {
  font-size: 16px;
  color: #272522;
}

.body {
  padding: 16px;
}

.commLabel {
  font-size: 10px;
  line-height: 11.93px;
  opacity: 0.5;
  margin-top: 8px;
  margin-bottom: 0px;
}
