.root {
  border-radius: 10px;
  background: rgba(73, 58, 177, 0.1);
  padding: 8px;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 16px;
}

.acitve {
  height: 100% !important;
  border-radius: 6px;
  background-color: #493ab1 !important;
  color: white !important;
}

.acitve img {
  filter: brightness(0) invert(1);
}

.tabLabel {
  gap: 4px;
  font-size: 16px;
  line-height: normal;
  z-index: 1;
  color: #686764d9;
  fill: #686764d9;
  padding: 6px;
  min-width: 0;
  min-height: 0;
  height: 31px;
  text-transform: none;
  cursor: pointer;
  transition: display 0.3s;
}

.tabIcon {
  font-size: 19px !important;
}
