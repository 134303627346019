.root {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 50px);
}

.body {
  padding: 24px;
  background-color: #f5f3fb;
  flex: 1;
}

.headerContainer {
  padding: 0 24px;
  height: 75.23px;
}

.tabRoot {
  background-color: white !important;
}

.tabSelected {
  background-color: #493ab1 !important;
}

.tables {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 8px;
}

.navAction {
  min-width: 48px !important;
}

.navAction svg {
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .body {
    padding: 16px;
  }

  .tables {
    gap: 32px;
  }

  .root {
    min-height: 100%;
  }
}
