.root {
  width: 100%;
}

.root tbody > tr:hover {
  background-color: #f5f6f97b;
}

.root td {
  padding: 16px 24px;
  color: #21272a;
  font-size: 14px;
}

.thead {
  background: #d8dde9;
}

.thead th {
  color: #21272a;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  padding: 16px 24px;
}
