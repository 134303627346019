.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.footer {
  position: sticky;
  bottom: 0;
}

@media screen and (max-width: 768px) {
  .footer {
    bottom: 72px;
    background-color: white;
    z-index: 1;
  }
}
