.root {
  padding: 24px !important;
  display: flex;
  flex-direction: column;
}

.root button {
  text-transform: none;
  justify-content: flex-start;
  color: #272522;
  font-size: 14px;
  line-height: normal;
  padding: 8px 0px;
}

.info {
  font-size: 18px !important;
  color: #1c1b1f !important;
  opacity: 0.5;
  margin-left: 16px;
}

@media screen and (max-width: 768px) {
  .root {
    padding: 16px !important;
  }
}
