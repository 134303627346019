.body {
  white-space: pre-wrap;
  max-width: 56vw;
}

@media screen and (max-width: 768px) {
  .body {
    padding: 16px 0;
    max-width: none;
  }
}
