.root {
  padding: 24px !important;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  gap: 8px;
}

@media screen and (max-width: 768px) {
  .root {
    padding: 16px !important;
  }
}
