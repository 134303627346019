.root {
  background: #302673;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  padding: 12px 24px;
}

.root button {
  border-radius: 15px;
  background-color: white;
  text-transform: none;
  color: #272522;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  padding: 8px 16px;
}

.root button:hover {
  background-color: white;
}

@media screen and (max-width: 768px) {
  .root {
    flex-direction: column;
    text-align: center;
    gap: 10px;
    padding: 12px 16px;
  }

  .root button {
    width: 100%;
  }
}
