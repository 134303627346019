.toolbar {
  position: sticky;
  padding: 10.25px !important;
  top: 48px;
  z-index: 1;
}

.body {
  padding: 16px 24px;
  white-space: pre-wrap;
}

.rte {
  max-height: none !important;
}

.variablesRoot {
  width: 500px;
  min-width: 0 !important;
  max-width: none !important;
}

@media screen and (max-width: 768px) {
  .toolbar {
    top: 64px;
  }

  .rte {
    padding-bottom: 120px !important;
  }

  .body {
    padding: 16px;
  }

  .variablesRoot {
    width: auto;
    max-height: 200px;
    overflow-y: auto;
  }
}
