.root {
  padding: 0px !important;
  background-color: #eef0f2;
}

.body {
  margin-top: 16px 0px;
}

.tabsWrapper {
  position: relative;
  padding: 16px 24px 0px;
  background-color: white;
}

.tabsRuler {
  position: absolute;
  border-bottom: 2px solid #e7e6e4;
  inset: auto 0 0 0;
  width: 100%;
}

.modalPaper {
  min-width: 568px;
}

@media screen and (max-width: 768px) {
  .root {
    padding: 0px !important;
  }

  .tabsWrapper {
    position: relative;
    padding: 16px 16px 0px;
  }
}
