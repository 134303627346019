.root {
  padding: 24px;
}

.body {
  white-space: pre-wrap;
}

@media screen and (max-width: 768px) {
  .root {
    padding: 16px;
  }
}
