.root {
  animation: none !important;
  max-width: 22vw;
}

.body {
  white-space: pre-wrap;
}

@media screen and (max-width: 768px) {
  .root {
    max-width: none;
  }
}
