.root {
  padding: 16px 24px;
  gap: 10px;
  border-bottom: 0.5px solid #e4e4e4;
}

.label {
  color: #959595;
  line-height: normal;
}

.variablesRoot {
  width: 500px;
  min-width: 0 !important;
  max-width: none !important;
}

@media screen and (max-width: 768px) {
  .root {
    padding: 16px;
  }

  .variablesRoot {
    width: auto;
    max-height: 200px;
    overflow-y: auto;
  }
}
