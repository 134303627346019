.title {
  color: #272522;
  font-size: 17.719px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4px;
}

.info {
  color: rgba(39, 37, 34, 0.7);
  font-size: 14px;
  line-height: normal;
}
