.commRow:hover {
  background-color: #f5f6f97b;
}

.titleCol {
  gap: 10px;
}

.expandCta {
  color: #111827 !important;
  font-size: 1rem !important;
}

.rotate {
  transform: rotate(90deg);
}

.commInfo {
  font-size: 0.75rem;
  line-height: 14.32px;
  color: #272522;
  opacity: 0.5;
  margin-top: 6px;
}

.actions {
  gap: 32px;
}
