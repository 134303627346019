.title {
  color: #272522;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 4px;
}

.info {
  color: #959595;
  font-size: 14px;
  line-height: normal;
  margin-bottom: 24px;
}
