.headerContainer {
  padding: 0 24px;
  height: 75.23px;
}

.commLabel {
  color: #272522;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
}
