.root {
  border-radius: 10px;
  overflow: hidden;
  border: 0.5px solid #d8dde9;
  background-color: white;
}

.tableRoot {
  width: 100%;
}

.tableRoot td,
th {
  padding: 1rem 1.125rem;
  user-select: none;
  font-size: 0.875rem;
  line-height: 0.963rem;
}

.header {
  background-color: #d8dde9;
}

.header th {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.068rem;
  color: #21272a;
}

.tableTitle {
  font-size: 1.125rem;
  line-height: 1.342rem;
  color: #111827;
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .tableTitle {
    font-size: 1rem;
    line-height: 1.193rem;
  }
}
