.root {
  max-width: 300px;
  position: relative;
}

.frame {
  width: 100%;
  pointer-events: none;
  z-index: 1;
  position: relative;
}

.screen {
  position: absolute;
  top: 0;
  padding: 12px 11px 24px 11px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  border-radius: 16%;
}

.screen::-webkit-scrollbar {
  display: none;
}

.header {
  background-color: #f9f9f9f0;
  padding-top: 30%;
  position: sticky;
  top: 0px;
  z-index: 1;

  border-bottom: 0.336px solid rgba(60, 60, 67, 0.36);
  background: linear-gradient(
      0deg,
      rgba(249, 249, 249, 0.94) 0%,
      rgba(249, 249, 249, 0.94) 100%
    ),
    rgba(30, 30, 30, 0.75);
  backdrop-filter: blur(6.719707489013672px);
}

.logoContainer {
  position: absolute;
  bottom: 5.23px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.profile {
  height: 32px;
  border-radius: 50%;
}

.logo {
  font-size: 7.392px;
  line-height: 8.736px;
  letter-spacing: 0.044px;
  text-align: center;
  margin-top: 3.36px;
}

.logo svg {
  font-size: 8px;
  color: #3c3c4399;
}

.backCta {
  position: absolute;
  bottom: 16px;
  left: 8px;
  color: #007aff !important;
}

.body {
  padding: 13.44px;
  overflow-y: auto;
}

.receivedAt {
  color: rgba(60, 60, 67, 0.6);
  font-size: 7.392px;
  font-weight: 500;
  line-height: 8.736px;
  letter-spacing: 0.04px;
}

.message {
  border-radius: 12.095px;
  background: #e9e9eb;
  padding: 10px;
  font-size: 12px;
  line-height: 14.783px;
  letter-spacing: -0.274px;
  width: 80%;
  margin-top: 5.38px;
  white-space: pre-wrap;
  position: relative;
}

.chatBubbleArrow {
  position: absolute;
  inset: auto auto 1px -3px;
  filter: brightness(0) saturate(100%) invert(91%) sepia(3%) saturate(29%)
    hue-rotate(180deg) brightness(95%) contrast(112%);
}
