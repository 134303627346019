.infoWrapper {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.commLabel {
  color: #272522;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
