.root {
  animation: rootAnim 0.3s ease forwards;
  position: relative;
  user-select: none;
}

@keyframes rootAnim {
  from {
    transform: translateX(30%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.header {
  gap: 8px;
  padding: 10% 0px 2% 5%;
  background-color: #01b779;
  color: white;
  font-weight: 700;
  font-size: 16px;
  position: sticky;
  top: 0px;
  user-select: none;
  z-index: 1;
}

.header > div {
  height: 32px;
  width: 32px;
}

.header img {
  height: 32px;
  width: 32px;
  max-height: 100%;
  max-width: 100%;
  border-radius: 50%;
  background: lightgray;
}

.templateContent {
  background-color: white;
  margin: 5% 0px 42px 5%;
  padding: 7.5px;
  font-size: 14px;
  max-width: 80%;
  word-break: break-all;
  line-height: 1.3;
  white-space: pre-wrap;
  border-radius: 8px;
  position: relative;
}

.screen {
  position: absolute;
  inset: 0;
  height: 100%;
  border-radius: 16%;
  overflow-y: auto;
  padding: 4.6% 4.1% 0px 4.2%;
  background: url(https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png);
  margin: 0px 3px;
}

.screen::-webkit-scrollbar {
  display: none;
}

.frame {
  width: 100%;
  pointer-events: none;
  z-index: 1;
  position: relative;
  max-height: 100%;
}

.chatBubbleArrow {
  position: absolute;
  inset: auto auto 1px -3px;
}

.profileLoaderContainer {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  height: 18px !important;
  width: 18px !important;
  color: white !important;
}

@media screen and (max-width: 768px) {
  .root {
    animation: none;
  }
}
