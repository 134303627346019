.root {
  box-shadow: 0px 4px 6px 0px #d8dde940;
  border: 0.25px solid #d8dde9;
  border-radius: 10px;
  background-color: white;
  animation: animateRoot 0.6s ease forwards;
}

@keyframes animateRoot {
  from {
    transform: translateY(10%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.tableTitle {
  font-size: 1rem;
  line-height: 1.193rem;
  margin-bottom: 12.5px;
}

.header {
  padding: 16px 8px 16px 12px;
  gap: 4px;
  border-bottom: 1px solid #e7e6e4;
}

.body {
  padding: 20px 16px;
}

.title {
  font-size: 14px;
  line-height: 16.7px;
  color: #21272a;
}

.info {
  font-size: 12px;
  line-height: 14.32px;
  color: #272522;
  opacity: 0.5;
  margin-top: 4px;
}

.commLabel {
  font-size: 10px;
  line-height: 11.93px;
  opacity: 0.5;
  margin-top: 8px;
  margin-bottom: 0px;
}
