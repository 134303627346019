.offeringRow {
  background-color: #f5f6f9;
}

.offeringTitleCol {
  padding-left: 3.5rem !important;
}

.offeringRow td {
  padding: 1.156rem 1rem;
}

.loadMoreTitle {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #493ab1 !important;
  text-transform: none !important;
  padding: 0px !important;
}
.loadMoreTitle svg {
  color: #493ab1;
  font-size: 15px;
}

.offerinNamePlaceholder {
  font-weight: 500 !important;
}

.loader {
  height: 24px !important;
  width: 24px !important;
}
