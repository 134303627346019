.root {
  padding: 24px !important;
}

.paper {
  min-width: 480px;
}

@media screen and (max-width: 768px) {
  .root {
    padding: 16px !important;
  }
}
