.root {
  background-color: white;
  border-top: 2px solid #e7e6e4;
  padding: 12px 24px;
}

@media screen and (max-width: 768px) {
  .root {
    padding: 10px 16px;
  }
}
