.actions {
  gap: 18px;
}

.action {
  text-transform: none !important;
  min-height: 0 !important;
  color: #493ab1 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

@media screen and (max-width: 768px) {
  .actions {
    position: fixed;
    inset: auto 0 0 0;
    padding: 16px 0px;
    margin: 0px 16px;
    border-top: 1px solid #e7e6e4;
    gap: 16px;
    z-index: 1;
    background-color: white;
  }

  .actions button {
    border-color: #493ab1;
  }

  .action {
    min-height: 32px !important;
    padding: 8px !important;
    font-size: 14px !important;
  }
}
