.root {
  padding: 24px !important;
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.checkboxLabel {
  color: #272522 !important;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
  margin-bottom: 16px;
}

.footer {
  justify-content: space-between !important;
}

@media screen and (max-width: 768px) {
  .root {
    padding: 16px !important;
  }
}
