.root {
  padding: 12px 16px 12px 24px;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .root {
    background: #f5f4f5;
    justify-content: center;
  }
}
