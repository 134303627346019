.root {
  padding: 12px 24px;
  background-color: #eeecf9;
  gap: 1.5rem;
}

.info {
  color: #272522;
  line-height: 23px;
  opacity: 0.5;
  font-size: 14px;
}

.info > svg {
  font-size: 17px;
  margin-top: -3px;
}
