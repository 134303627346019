.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.section {
  padding: 16px 24px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sectionLabel {
  color: #272522;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 8px;
}

.sectionLabel button {
  padding: 0px;
  color: #fa0 !important;
}

.sectionLabel svg {
  font-size: 20px;
}

.loader {
  background-color: white;
}

@media screen and (max-width: 768px) {
  .section {
    padding: 16px;
  }
}
