.header {
  padding: 10px 24px;
  border-bottom: 1.5px solid #e7e6e4;
  gap: 1rem;
}

.body {
  padding: 18px 24px;
  max-width: 56vw;
}
