@media screen and (max-width: 768px) {
  .root {
    flex-direction: column;
    padding: 12px 16px;
    gap: 8px;
  }

  .root button {
    width: 100% !important;
  }
}
