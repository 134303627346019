.label {
  color: #21272a;
  font-size: 16px;
  font-weight: 500;
}

.info {
  color: #21272a;
  font-size: 16px;
  line-height: normal;
  opacity: 0.5;
  margin-top: 8px;
}
