.root {
  border-radius: 8px;
  border: 1px solid #e7e6e4;
  box-shadow: 0px 4px 8px 0px rgba(164, 163, 174, 0.2);
  transition: all 0.2s;
  overflow: hidden;
  position: relative;
}

.template {
  padding: 12px;
  background: #eeecf9;
  white-space: pre-wrap;
  max-height: 108px;
  overflow: hidden;
}

.footer {
  padding: 12px;
  gap: 8px;
}

.commLabel {
  color: #272522;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
}

.listingType {
  color: #493ab1;
  font-size: 12px;
  line-height: normal;
  padding: 4px 6px;
  border-radius: 5px;
  background: #e0dcf4;
  white-space: nowrap;
}

.actions {
  position: absolute;
  inset: 0;
  background: rgba(39, 37, 34, 0.5);
  display: grid;
  place-items: center;
  animation: animateRoot 0.3s ease forwards;
  align-items: center;
}

@keyframes animateRoot {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.previewCta {
  border-radius: 5px !important;
  border: 1px solid #493ab1 !important;
  background-color: white !important;
  color: #493ab1 !important;
  animation: previewCtaAnim 0.3s ease forwards;
}

@keyframes previewCtaAnim {
  from {
    transform: translateY(30%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.selectedTemplateCta {
  animation: selectTemplateCta 0.3s ease forwards;
}

@keyframes selectTemplateCta {
  from {
    transform: translateY(-30%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .mobileActions {
    padding: 16px 12px;
  }
}
